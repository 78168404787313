import React, { useState, useEffect } from 'react';

const Ip = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      fetch("https://168.u168phone.com/ip")   //------------------------------
        .then(res => res.json())
        .then(fetchedData => {
          setData(fetchedData);  // ตั้งค่า state data ด้วยข้อมูลที่ดึงมา
        })
        .catch(err => {
          console.error("Error fetching IP:", err);
        });
    }, 50000);  // ทำการ fetch หลังจากผ่านไป 5 วินาที

    // Cleanup function
    return () => {
      clearTimeout(timer);
    };
  }, []);  // Empty dependency array ทำให้ useEffect ทำงานครั้งเดียวหลังจาก render ครั้งแรก

  return (
    <div>
      {data ? JSON.stringify(data) : "Loading..."}
    </div>
  );
};

export default Ip;
